import service from "../service.js";

export const searchDeviceData = params => {
    return service({
      method: 'POST',
      url: '/device/serchDevice',
      params
    })
}



export const bindCode = params => {
  return service({
    method: 'POST',
    url: '/device/bindCode',
    params
  })
}

export const addDevice = (data) => {
  return service({
    method: 'POST',
    url: '/device/addDevice',
    data
  })
}

export const updateDevice = (data) => {
  return service({
    method: 'POST',
    url: '/device/updateDevice',
    data
  })
}

// export const scrapDevice = (data) => {
//   return service({
//     method: 'POST',
//     url: '/device/scrapDevice',
//     params: {
//       data
//     }
//   })
// }

export const scrapDevice = params => {
  return service({
    method: 'POST',
    url: '/device/scrapDevice',
    params
  })
}

export const importExcel = data => {
  return service({
    method: 'POST',
    url: '/device/importExcel',
    data
  })
}


export const queryDevice = (id) => {
  return service({
    method: 'GET',
    url: '/device/queryDevice',
    params: {
      id
    }
  })
}

export const getDevice = (deviceCode) => {
  return service({
    method: 'GET',
    url: '/device/getDevice',
    params: {
      deviceCode
    }
  })
}

export const getDeviceClassList = () => {
  return service({
    method: 'GET',
    url: '/device/getDeviceClassList',
  })
}

export const getDeviceNameList = (deviceOneClass) => {
  return service({
    method: 'GET',
    url: '/device/getDeviceNameList',
    params: {
      deviceOneClass
    }
  })
}

export const getDeviceRealNameList = (deviceNameCode) => {
  return service({
    method: 'GET',
    url: '/device/getDeviceRealNameList',
    params: {
      deviceNameCode
    }
  })
}


export const deviceStatusList= [{code:'00',name:'正常'},{code:'01',name:'装备待维修警示'},{code:'02',name:'装备待维修注意'},{code:'03',name:'附件待维修警示'},{code:'04',name:'附件待维修注意'},{code:'05',name:'装备待校准校验'},{code:'06',name:'修后性能待查'},{code:'07',name:'校后性能待查'},{code:'08',name:'还后性能待查'},{code:'09',name:'报废移除'},{code:'10',name:'借入归还'},{code:'11',name:'租赁移除'}]
export const crkStatusList = [{code:'00',name:'已入库'},{code:'01',name:'预出库'},{code:'02',name:'正常使用出库'},{code:'03',name:'外送维修出库'},{code:'04',name:'外送校验出库'},{code:'05',name:'借出使用出库'},{code:'06',name:'转移库房出库'},{code:'07',name:'使用返回入库'},{code:'08',name:'维修返回入库'},{code:'09',name:'校验返回入库'},{code:'10',name:'借出归还入库'},{code:'11',name:'仪器暂存入库'},{code:'12',name:'使用未返回'},{code:'13',name:'使用未返回告警'},{code:'14',name:'借出未归还'}]



