import service from "../service.js";

//查询仪器仪表件分类信息
export const serchDeviceClass = params => {
    return service({
      method: 'POST',
      url: '/deviceClass/serchDeviceClass',
      params
    })
}


//获取所有以及分类信息
export const getListDeviceClass = () => {
  return service({
    method: 'GET',
    url: '/deviceClass/getListDeviceClass',
  })
}



//删除仪器仪表件分类信息
export const deleteDeviceClass = (id) => {
    return service({
      method: 'GET',
      url: '/deviceClass/deleteDeviceClass',
      params: {
        id
      }
    })
  }

export const addDeviceClass = (data) => {
  return service({
    method: 'POST',
    url: '/deviceClass/addDeviceClass',
    data
  })
}

export const updateDeviceClass = (data) => {
  return service({
    method: 'POST',
    url: '/deviceClass/updateDeviceClass',
    data
  })
}

export const getDeviceClass = (id) => {
  return service({
    method: 'GET',
    url: '/deviceClass/getDeviceClass',
    params: {
      id
    }
  })
}

export const getDeviceClassByLevel = (level) => {
    return service({
      method: 'GET',
      url: '/deviceClass/getDeviceClassByLevel',
      params: {
        level
      }
    })
  }
